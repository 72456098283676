import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Container, Col, Card } from 'react-bootstrap/'
import TopHeader from "../components/top-header"

import moduleKullanici from "../images/modules/Kullanici_Arayuzu.svg"
import moduleUretim from "../images/modules/Uretim_vekasi.svg"
import moduleIzleme from "../images/modules/izleme_analiz.svg"
import moduleVeri from "../images/modules/Veri_depolama.svg"
import moduleYonetim from "../images/modules/Yonetim.svg"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const Moduller = ({ intl }) => {

  return <Layout >
    <TopHeader />
    <SEO
      title={intl.formatMessage({ id: "modules_title" })}
      description='Retmes Nesnelerin İnterneti (IoT), İstatistiksel Proses Kontrolü, Makine Öğrenimi, Proses Optimizasyonu, Ekipman Yönetimi ve OEE, İstatistiksel Proses Kontrolü modülleri.'
    />
    <Container>
      <h1 className='page-title'>{intl.formatMessage({ id: "modules_title" })} </h1>
      <Row className='modules-included-card-row'>
        <Card className='modules-included-card'>
          <Card.Body className='modules-included-card-body'>
            <img src={moduleVeri} style={{ width: '4rem' }} alt="" />
            <Card.Title as='h2' className='modules-included-card-title'><FormattedMessage id="modules_title1__1" /></Card.Title>
            <Card.Text as='ul' className='modules-included-card-text'>
              <li>
                <Link className='modules-link' to="/moduller/veri-ve-kontrol/nesnelerin-interneti-iot"><FormattedMessage id="modules_content1__1" /></Link>
              </li>
              <li>
                <Link className='modules-link' to="/moduller/veri-ve-kontrol/veri-toplama-servisleri"><FormattedMessage id="modules_content1__2" /></Link>
              </li>
              <li>
                <Link className='modules-link' to="/moduller/veri-ve-kontrol/gecmise-donuk-veri-kaydi-hizmeti"><FormattedMessage id="modules_content1__3" /></Link>
              </li>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='modules-included-card'>
          <Card.Body className='modules-included-card-body'>
            <img src={moduleIzleme} style={{ width: '4rem' }} alt="" />
            <Card.Title as='h2' className='modules-included-card-title'><FormattedMessage id="modules_title2__1" /></Card.Title>
            <Card.Text as='ul' className='modules-included-card-text'>
              <li><Link className='modules-link' to="/moduller/izleme-ve-analiz/gercek-zamanli-izleme"><FormattedMessage id="modules_content2__1" /></Link></li>
              <li><Link className='modules-link' to="/moduller/izleme-ve-analiz/raporlama-servisleri"><FormattedMessage id="modules_content2__2" /></Link></li>
              <li><Link className='modules-link' to="/moduller/izleme-ve-analiz/veri-analizi"><FormattedMessage id="modules_content2__3" /></Link></li>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='modules-included-card'>
          <Card.Body className='modules-included-card-body'>
            <img src={moduleKullanici} style={{ width: '4rem' }} alt="" />
            <Card.Title as='h2' className='modules-included-card-title'><FormattedMessage id="modules_title3__1" /></Card.Title>
            <Card.Text as='ul' className='modules-included-card-text'>
              <li><Link className='modules-link' to="/moduller/kullanici-arayuzu/web"><FormattedMessage id="modules_content3__1" /></Link></li>
              <li><Link className='modules-link' to="/moduller/kullanici-arayuzu/rest-api"><FormattedMessage id="modules_content3__2" /></Link></li>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='modules-included-card'>
          <Card.Body className='modules-included-card-body'>
            <img src={moduleYonetim} style={{ width: '4rem' }} alt="" />
            <Card.Title as='h2' className='modules-included-card-title'><FormattedMessage id="modules_title4__1" /></Card.Title>
            <Card.Text as='ul' className='modules-included-card-text'>
              <li><Link className='modules-link' to="/moduller/yonetim/kural-ve-bildirim-yonetimi"><FormattedMessage id="modules_content4__1" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/bakim-ve-servis-yonetimi"><FormattedMessage id="modules_content4__2" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/recete-yonetimi"><FormattedMessage id="modules_content4__3" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/kalite-yonetimi"><FormattedMessage id="modules_content4__4" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/ekipman-yonetimi-ve-oee"><FormattedMessage id="modules_content4__5" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/enerji-yonetimi"><FormattedMessage id="modules_content4__6" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/tuketim-yonetimi"><FormattedMessage id="modules_content4__7" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/gorev-yonetimi"><FormattedMessage id="modules_content4__8" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/is-emri-planlamasi"><FormattedMessage id="modules_content4__9" /></Link></li>
              <li><Link className='modules-link' to="/moduller/yonetim/kullanici-yonetimi"><FormattedMessage id="modules_content4__10" /></Link></li>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='modules-included-card'>
          <Card.Body className='modules-included-card-body'>
            <img src={moduleUretim} style={{ width: '4rem' }} alt="" />
            <Card.Title as='h2' className='modules-included-card-title'><FormattedMessage id="modules_title5__1" /></Card.Title>
            <Card.Text as='ul' className='modules-included-card-text'>
              <Link className='modules-link' to="/moduller/uretim-zekasi/istatistiksel-proses-kontrolu"><h3><FormattedMessage id="modules_content5__1" /></h3></Link>
              <Link className='modules-link' to="/moduller/uretim-zekasi/proses-analizi"><h3><FormattedMessage id="modules_content5__2" /></h3></Link>
              <Link className='modules-link' to="/moduller/uretim-zekasi/proses-optimizasyonu"><h3><FormattedMessage id="modules_content5__3" /></h3></Link>
              <Link className='modules-link' to="/moduller/uretim-zekasi/makine-ogrenimi"><h3><FormattedMessage id="modules_content5__4" /></h3></Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row className='home-row-2'>
        <Col sm style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '0px' }} className='head-col-1'  >
          <strong className='support-title'><FormattedMessage id="modules_ad_title_h1" /></strong> <br />
          <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_desc_p" /></p> <br />
          <Link to='/iletisim'> <div className='retmes-detail-button'><FormattedMessage id="get_support" /> </div></Link>
        </Col>
      </Row>
    </Container>
  </Layout >

}

export default injectIntl(Moduller)
